import { createFileRoute, Outlet } from "@tanstack/react-router";
import { AdminScrollPane } from "../../admin/AdminLayout.tsx";

/** The routes under this will display their contents in a single pane */
export const Route = createFileRoute("/admin/_simple")({
    component: () => (
        <AdminScrollPane>
            <Outlet />
        </AdminScrollPane>
    ),
});
