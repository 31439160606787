import type {
    ManagedServicePriceTypeEnum,
    ManagedServicesPricesBody,
    ManagedServicesVmBody,
    ManagedServicesVmStorageReplica,
    PriceFieldsBase,
    PricingList,
    ResourceTypePrices,
    UptimeName,
    VirtualMachineStorage,
    VirtualMachineStorageReplica,
    VmStatusEnum,
} from "@warrenio/api-spec/spec.oats.gen";
import { filterNulls } from "@warrenio/utils/collections/filterNulls";
import { getOwn } from "@warrenio/utils/collections/getOwnProperty";
import { entries, findLast, mapValues, sortBy } from "remeda";
import invariant from "tiny-invariant";
import type { LiteralUnion } from "type-fest";
import { showError } from "../../dev/errorStream.ts";
import { produce } from "../../utils/immer.ts";
import { getOptionalVmPool, type VmPoolFields } from "../compute/vmLogic.ts";
import type { VirtualMachineLoc } from "../compute/vmQuery.ts";
import type { IpAddressWithType } from "../network/ipAddress/apiOperations.ts";
import type { ManagedServiceLoc } from "../services/servicesQuery.ts";
import type { StorageWithType } from "../storage/objectStorage/apiOperations.ts";

export interface Price {
    hourly: number;
    /**
     * `true` if this price was calculated from an empty list of items.
     *
     * Useful because a price of 0 can mean "free" or "not set" depending on the context.
     */
    empty?: boolean;
    unit?: "GB";

    //#region For informational purposes
    multiplier?: number;
    description?: string;
    items?: Price[];
    //#endregion
}

export interface ItemWithPrice {
    price: Price;
}

const DEFAULT_KEY = "DEFAULT";

//#region Price math utilities

export const FREE_PRICE: Price = { hourly: 0 };
export const EMPTY_PRICE: Price = { hourly: 0, empty: true };

function addPrice(a: Price, b: Price): Price {
    return { hourly: a.hourly + b.hourly, empty: a.empty && b.empty };
}

function mulPrice(price: Price, multiplier: number): Price {
    return { hourly: price.hourly * multiplier, empty: price.empty, multiplier };
}

type OptionalPrice = Price | undefined;

function addPrices(...prices: OptionalPrice[]) {
    return sumPrices(filterNulls(prices), (price) => price);
}

function sumPrices<T>(items: T[] | undefined, getPrice: (item: T) => OptionalPrice): Price {
    if (!items || items.length === 0) {
        return EMPTY_PRICE;
    }

    let hourly = 0;
    let empty = true;
    let prices: Price[] | undefined;
    for (const item of items) {
        const p = getPrice(item);
        if (p !== undefined && !p.empty) {
            hourly += p.hourly;
            (prices ??= []).push(p);

            empty = false;
        }
    }

    return { hourly, empty, items: prices };
}

//#endregion

//#region Price list utilities

export function getLocationPrices(
    priceRules: ResourcePrices,
    location: string,
    poolId?: string | null,
): ResourceTypeToPrices {
    const defaultPrices = priceRules[DEFAULT_KEY];
    const locationPrices = getOwn(priceRules, location) ?? defaultPrices;
    if (poolId != null) {
        const poolPrices = getOwn(priceRules, `host_pool:${poolId}`);
        // Merge host pool and location level prices
        return { ...locationPrices, ...poolPrices };
    }
    return locationPrices;
}

//#endregion

//#region Per-resource-type price logic

/** Resource types that have prices. */
export type PriceResourceType =
    | ResourceTypePrices["resource_type"]
    // Pseudo-type for service disk price calculation
    | "STORAGE_main";

type ResourceTypeToPrices_Base = {
    [resourceType in ResourceTypePrices["resource_type"]]?: (ResourceTypePrices & {
        resource_type: resourceType;
    })["resource_prices"];
};

/** Object where the key is the resource type and the value is the price list. */
export type ResourceTypeToPrices = ResourceTypeToPrices_Base & {
    /** Fake field added in {@link getServicePriceList} */
    STORAGE_main?: ResourceTypeToPrices_Base["BLOCK_STORAGE"];
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/consistent-indexed-object-style
export type ResourcePrices = {
    [locationOrPool: string]: ResourceTypeToPrices;
};

export const EMPTY_PRICES: ResourcePrices = { [DEFAULT_KEY]: {} };

export function listToObjectPrices(list: PricingList, currency: string): ResourcePrices {
    const currencyPriceRules = list[currency];
    if (currencyPriceRules == null) {
        showError(`No price rules found for currency ${currency}`);
        return EMPTY_PRICES;
    }

    return mapValues(currencyPriceRules, (locationPrices) => {
        const obj: Record<string, object> = {};
        for (const { resource_prices, resource_type } of locationPrices) {
            // If the prices array is empty, treat it as if no price has been set
            if (resource_prices.length === 0) {
                continue;
            }

            // Sort so we can use `findLast()` below
            obj[resource_type] =
                resource_prices.length === 1
                    ? resource_prices
                    : sortBy(resource_prices, [(price) => getSizeField(resource_type, price) ?? 0, "asc"]);
        }
        return obj as ResourceTypeToPrices;
    });
}

function getSizeField(resourceType: PriceResourceType, item: PriceFieldsBase): number | undefined {
    if (resourceType === "CPU") {
        return item.num_cpus_from;
    }
    if (
        resourceType === "RAM" ||
        resourceType === "BLOCK_STORAGE" ||
        resourceType === "STORAGE_main" ||
        resourceType === "BLOCK_STORAGE_SNAPSHOT" ||
        resourceType === "BLOCK_STORAGE_BACKUP"
    ) {
        return item.gigabytes_from;
    }
    return undefined;
}

function getSingleResourcePrice<const T extends PriceResourceType>(
    prices: ResourceTypeToPrices,
    resourceType: T,
    resourceSize?: number,
): Price {
    let hourly;
    let description;

    const resourcePrices = getOwn(prices, resourceType);
    if (!resourcePrices) {
        hourly = 0;
        description = resourceType;
    } else {
        const matchingResult =
            resourceSize == null
                ? // With no graduated pricing, assume there is only one price
                  resourcePrices[0]
                : // Prices are sorted ascending so find the last one still within the size
                  findLast(
                      resourcePrices,
                      (price) =>
                          // If no size field is present, assume it applies to all sizes (from 0)
                          (getSizeField(resourceType, price) ?? 0) <= resourceSize,
                  );

        // If there is no matching price, it is considered free
        const perUnit = matchingResult?.price_per_unit ?? 0;
        hourly = perUnit * (resourceSize ?? 1);

        description = matchingResult?.description ?? resourceType;
    }

    return {
        hourly,
        multiplier: resourceSize,
        description,
    };
}

export function getLoadBalancerPrice(prices: ResourcePrices, location: string, addIpPrice: boolean): Price {
    const price = getSingleResourcePrice(getLocationPrices(prices, location), "LOAD_BALANCER");
    return addIpPrice ? addPrice(price, getAssignedIpAddressPrice(prices, location)) : price;
}

function getLocationAssignedIpAddressPrice(locationPrices: ResourceTypeToPrices): Price {
    return getSingleResourcePrice(locationPrices, "ASSIGNED_FLOATING_IP");
}

export function getAssignedIpAddressPrice(prices: ResourcePrices, location: string): Price {
    return getLocationAssignedIpAddressPrice(getLocationPrices(prices, location));
}

export function getUnassignedIpAddressPrice(prices: ResourcePrices, location: string): Price {
    return getSingleResourcePrice(getLocationPrices(prices, location), "UNASSIGNED_FLOATING_IP");
}

export interface IpAddressPrices {
    assigned: Price;
    unassigned: Price;
}

export function getIpAddressPrices(priceList: ResourcePrices, location: string): IpAddressPrices {
    return {
        assigned: getAssignedIpAddressPrice(priceList, location),
        unassigned: getUnassignedIpAddressPrice(priceList, location),
    };
}

export function getIpAddressPrice(prices: ResourcePrices, item: IpAddressWithType): Price {
    const ipPrices = getIpAddressPrices(prices, item.location);
    return item.assigned_to ? ipPrices.assigned : ipPrices.unassigned;
}

export function getBucketPrice(prices: ResourcePrices, item: StorageWithType): Price {
    const gb = Math.ceil(item.size_bytes / 1024 / 1024 / 1024);
    const perGb = getSingleResourcePrice(getLocationPrices(prices, DEFAULT_KEY), "OBJECT_STORAGE", gb);
    return { ...perGb, unit: "GB" };
}

export function getBucketPricePerGB(prices: ResourcePrices): Price {
    const perGb = getSingleResourcePrice(getLocationPrices(prices, DEFAULT_KEY), "OBJECT_STORAGE");
    return { ...perGb, unit: "GB" };
}

//#endregion

//#region Fake service price lists

const defaultMultipliers: Partial<
    Record<ManagedServicePriceTypeEnum | `${ManagedServicePriceTypeEnum}.${UptimeName}`, number>
> = {
    BLOCK_STORAGE_SNAPSHOT: 0,
    BLOCK_STORAGE_BACKUP: 0,
};

function findPriceMultiplier(
    priceFields: ServicePriceFields,
    resourceType: ManagedServicePriceTypeEnum,
    nameInUptime?: UptimeName,
): number | undefined {
    for (const pm of priceFields.price_multipliers) {
        if ("resourceType" in pm && pm.resourceType === resourceType) {
            if (
                !nameInUptime ||
                ("serviceNameInUptime" in pm && nameInUptime === pm.serviceNameInUptime && pm.priceMultiplier)
            ) {
                return pm.priceMultiplier;
            }
        }
    }

    return defaultMultipliers[nameInUptime ? (`${resourceType}.${nameInUptime}` as const) : resourceType];
}

function getServicePriceList(prices: ResourcePrices, service: ServicePriceFields) {
    const priceList = getLocationPrices(prices, service.location);
    return produce(priceList, (draft) => {
        if ("BLOCK_STORAGE" in draft) {
            draft.STORAGE_main = draft.BLOCK_STORAGE; // make fresh copy first, apply custom multiplier later (code below)
        }

        for (const [resourceType, value] of entries(draft)) {
            for (const item of value) {
                const multiplier =
                    resourceType === "STORAGE_main"
                        ? findPriceMultiplier(service, "STORAGE", "main")
                        : findPriceMultiplier(service, resourceType as ManagedServicePriceTypeEnum);

                if (multiplier != null) {
                    item.price_per_unit *= multiplier;
                    if (import.meta.env.DEV) {
                        item.description += ` [x ${multiplier}]`;
                    }
                }
            }
        }
    });
}

//#endregion

//#region Virtual machine price logic

export interface VmLocationFields extends Pick<VirtualMachineLoc, "location">, VmPoolFields {}

/**
 * Common fields shared between compute VMs and service VMs.
 * Can be used to calculate prices and display eg. size information.
 */
export interface MachineCommonFields extends Pick<VirtualMachineLoc, "vcpu" | "memory" | "location"> {
    storage: VmStoragePriceFields[];
    // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents -- ESLint bug
    status: VirtualMachineLoc["status"] | Exclude<ManagedServicesVmBody["resource_allocation"]["status"], undefined>;
}

/** Fields required to calculate the price of a virtual machine or service. */
export interface VmPriceFields
    extends VmLocationFields,
        Pick<VirtualMachineLoc, "vcpu" | "memory" | "os_name" | "os_version" | "status" | "license_type"> {
    storage: VmStoragePriceFields[];
}

/** Check that {@link VmPriceFields} is a valid {@link MachineCommonFields} */
const _check_vm: MachineCommonFields = null! as VmPriceFields;

export interface VmPriceComponents {
    cpuPrice: Price;
    ramPrice: Price;
    disksPrice: Price;
    snapshotsPrice: Price;
    backupsPrice: Price;
    windowsLicensePrice: Price | undefined;
    ipPrice: Price;

    total: Price;
}

export function getVmCreatePrice(prices: ResourcePrices, vm: VmPriceFields, addIpPrice = false): Price {
    return VmPriceCalculator.fromPrices(prices, vm).calculatePrice(addIpPrice).total;
}

//#endregion

//#region Managed service price logic

export interface ServicePriceFields
    extends Pick<ManagedServiceLoc, "location">,
        Pick<VirtualMachineLoc, "license_type">,
        Required<Pick<ManagedServicesVmBody["resource_allocation"], "vcpu" | "memory">> {
    storage: VmStoragePriceFields[];
    os_name: LiteralUnion<ManagedServiceLoc["service"], string>;
    status: VmStatusEnum;
    price_multipliers: ManagedServicesPricesBody;
}

/** Check that {@link ServicePriceFields} is a valid {@link MachineCommonFields} */
const _check_service: MachineCommonFields = null! as ServicePriceFields;

export function getServiceCreatePrice(
    prices: ResourcePrices,
    service: ServicePriceFields,
    addIpPrice: boolean,
    failover_nodes: number,
): Price {
    const vmPrice = ServicePriceCalculator.fromPrices(prices, service).calculatePrice(
        // Do not add IP price for failover nodes
        false,
    );

    let total = mulPrice(vmPrice.total, failover_nodes + 1);
    if (addIpPrice) {
        total = addPrice(total, vmPrice.ipPrice);
    }
    return total;
}

export function getAppendedServiceFailoverPrice(
    prices: ResourcePrices,
    service: ServicePriceFields,
    addIpPrice: boolean,
    failover_nodes: number,
): Price {
    return getServiceCreatePrice(prices, service, addIpPrice, failover_nodes - 1);
}

const DUMMY_STORAGE: VmStoragePriceFields[] = [{ size: 0, primary: true, replica: [] }];

export function convertServiceVmToMachine(r: ManagedServicesVmBody, service: ManagedServiceLoc) {
    invariant(r.resource_type === "vm");
    const storage: VmStoragePriceFields[] =
        r.resource_allocation.storage?.map(({ size, primary, replica }) => ({
            size,
            primary,
            replica,
        })) ?? DUMMY_STORAGE;
    // NB: These fields can be missing in case the VM is in some unknown state
    const { memory = 0, vcpu = 0, status = "deleted" } = r.resource_allocation;
    return {
        memory,
        vcpu,
        status,
        storage,
        location: r.resource_location ?? service.location,
    } satisfies MachineCommonFields;
}

function getServiceVmPrice(r: ManagedServicesVmBody, service: ManagedServiceLoc, prices: ResourcePrices) {
    const priceFields: ServicePriceFields = {
        ...convertServiceVmToMachine(r, service),

        price_multipliers: service.prices,
        os_name: service.service,
    };

    return ServicePriceCalculator.fromPrices(prices, priceFields).prices;
}

export function getServicePrice(prices: ResourcePrices, service: ManagedServiceLoc): Price {
    const serviceVms = service.resources.filter((r) => r.resource_type === "vm");
    return sumPrices(serviceVms, (vm) => getServiceVmPrice(vm, service, prices).total);
}

//#endregion

//#region VM price calculator

abstract class PriceCalculator {
    constructor(
        protected readonly vm: VmPriceFields | ServicePriceFields,
        protected readonly locationPrices: ResourceTypeToPrices,
    ) {}

    private _prices?: VmPriceComponents = undefined;
    get prices() {
        return (this._prices ??= this.calculatePrice(false));
    }

    calculatePrice(addIpPrice: boolean): VmPriceComponents {
        //#region Base component prices
        const { memory, vcpu, storage, status, license_type } = this.vm;
        const ramInGb = memory / 1024;

        const cpuPrice = status === "running" ? getSingleResourcePrice(this.locationPrices, "CPU", vcpu) : FREE_PRICE;
        const ramPrice =
            status === "running" ? getSingleResourcePrice(this.locationPrices, "RAM", ramInGb) : FREE_PRICE;
        const ipPrice = getLocationAssignedIpAddressPrice(this.locationPrices);
        //#endregion

        //#region Storage prices
        const disksPrice = sumPrices(storage, (d) => this.getDiskPrice(d));
        const snapshotsPrice = sumPrices(storage, ({ replica }) =>
            sumPrices(
                replica?.filter((r) => r.type === "snapshot"),
                (r) => this.getReplicaPrice(r),
            ),
        );

        const backupsPrice = sumPrices(storage, ({ replica }) =>
            sumPrices(
                replica?.filter((r) => r.type === "backup"),
                (r) => this.getReplicaPrice(r),
            ),
        );
        //#endregion

        const windowsLicensePrice =
            license_type === "WINDOWS"
                ? getSingleResourcePrice(this.locationPrices, "WINDOWS_LICENSE", vcpu)
                : undefined;

        return {
            cpuPrice,
            ramPrice,
            disksPrice,
            snapshotsPrice,
            backupsPrice,
            ipPrice,
            windowsLicensePrice,
            total: addPrices(
                cpuPrice,
                ramPrice,
                disksPrice,
                snapshotsPrice,
                backupsPrice,
                addIpPrice ? ipPrice : undefined,
                windowsLicensePrice,
            ),
        };
    }

    getReplicaPrice(r: VmReplicaPriceFields) {
        return getReplicaPrice(this.locationPrices, r);
    }

    abstract getDiskPrice(storage: VmStoragePriceFields): Price;
}

export class VmPriceCalculator extends PriceCalculator {
    static fromPrices(priceList: ResourcePrices, vm: VmPriceFields) {
        return new VmPriceCalculator(vm, getLocationPrices(priceList, vm.location, getOptionalVmPool(vm)));
    }

    getDiskPrice(storage: VmStoragePriceFields) {
        return getDiskPrice(this.locationPrices, storage, false);
    }
}

export class ServicePriceCalculator extends PriceCalculator {
    static fromPrices(priceList: ResourcePrices, vm: ServicePriceFields) {
        return new ServicePriceCalculator(vm, getServicePriceList(priceList, vm));
    }

    getDiskPrice(storage: VmStoragePriceFields) {
        return getDiskPrice(this.locationPrices, storage, true);
    }
}

//#endregion

//#region Virtual machine storage price logic

/** Fields required to calculate the price of virtual machine storages. */
export interface VmStoragePriceFields extends Pick<VirtualMachineStorage, "size" | "primary"> {
    replica?: VirtualMachineStorageReplica[] | ManagedServicesVmStorageReplica[];
}

export interface VmReplicaPriceFields extends Pick<VirtualMachineStorageReplica, "size" | "type"> {}

function getReplicaPrice(locationPrices: ResourceTypeToPrices, r: VmReplicaPriceFields) {
    const resourceType = r.type === "snapshot" ? "BLOCK_STORAGE_SNAPSHOT" : "BLOCK_STORAGE_BACKUP";
    return getSingleResourcePrice(locationPrices, resourceType, r.size);
}

function getDiskPrice(locationPrices: ResourceTypeToPrices, storage: VmStoragePriceFields, isService: boolean) {
    const resourceType = isService && storage.primary ? "STORAGE_main" : "BLOCK_STORAGE";
    return getSingleResourcePrice(locationPrices, resourceType, storage.size);
}

//#endregion
