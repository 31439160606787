import { makeOnce } from "@warrenio/utils/makeOnce";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useAtomValue } from "jotai/react";
import { atom } from "jotai/vanilla";
import { useEffect } from "react";
import { isBuiltRelease } from "../../utils/environment.ts";
import { loadScript } from "../../utils/loadScript.ts";

declare global {
    // eslint-disable-next-line no-var
    var dataLayer: IArguments[];
}

// XXX: Need to extend the `gtag.js` type definitions since they are incomplete
interface GtagCustomConfig extends Gtag.ConfigParams {
    debug_mode?: boolean;
}

const loadGtag = makeOnce((tagId: string, config: GtagCustomConfig) => {
    const scriptUrl = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;

    // Load in the background since we stub out the gtag function
    discardPromise(loadScript(scriptUrl));

    window.dataLayer = window.dataLayer || [];

    window.gtag = function gtag() {
        /* eslint-disable prefer-rest-params */
        // biome-ignore lint/style/noArguments: The SDK requires using the `arguments` object
        window.dataLayer.push(arguments);
        /* eslint-enable prefer-rest-params */
    };

    window.gtag("js", new Date());

    window.gtag("config", tagId, config);
});

function gtagEvent(event: string, params?: Record<string, unknown>) {
    window.gtag("event", event, params);
}

interface PageViewEvent {
    event: "page_view";
    page_location: string;
    page_referrer?: string;
    engagement_time_msec?: number;
}

type AnalyticsEvent = PageViewEvent;

interface AnalyticsProvider {
    load(): void;
    event(event: AnalyticsEvent): void;
}

// TODO: Set up a generic event bus structure instead of buffering events at the provider level?

export const analyticsAtom = atom<AnalyticsProvider>((get) => {
    return {
        load() {
            loadGtag("G-XGF7GZCCVL", { debug_mode: !isBuiltRelease, send_page_view: false });
            gtagEvent("initialize");
        },
        event(event) {
            console.log("Event", event);
        },
    };
});

export function useInitializeAnalytics() {
    const analytics = useAtomValue(analyticsAtom);

    useEffect(() => {
        analytics.load();
    }, [analytics]);
}
