import { useEffect } from "react";
import { notifyError } from "./dev/errorReporting.tsx";
import { ErrorFallback } from "./modules/main/ErrorFallback.tsx";

export function RouterErrorFallback({ error }: { error: unknown }) {
    // Since the router swallows errors, we need to report them here
    // TODO: Check https://github.com/TanStack/router/issues/1513#issuecomment-2473003905
    useEffect(() => {
        if (error instanceof Error) {
            notifyError(error, { tags: "route_error" });
        }
    }, [error]);
    return <ErrorFallback error={error} />;
}
