import { queryOptions, useMutation, type QueryKey } from "@tanstack/react-query";
import type { AccessImpersonation, AdminImpersonateBody } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries } from "@warrenio/utils/collections/maps";
import { useStore } from "jotai/react";
import type { createStore } from "jotai/vanilla";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { mutationOptions } from "../../../utils/query/runMutation.ts";
import { apiClientAtom } from "../../api/apiClient.store.ts";
import { getResponseData, type ApiClient } from "../../api/apiClient.ts";
import type { Store } from "../../api/useStandardMutation.ts";
import { raiseRequestToast, type Toast } from "../../notifications/toast.tsx";
import { startImpersonationAction } from "../../user/impersonation.action.ts";

//#region basic Types/Contracts

export type Response = Map<AccessImpersonation["uuid"], AccessImpersonationWithType>;

export interface AccessImpersonationWithType extends AccessImpersonation {
    $type: "access_impersonation";
}

export const emptyResponse: Response = new Map();

export const queryKey: QueryKey = ["/user-resource/impersonation"];

//#endregion

//#region Queries

const toastOptions: Partial<Toast> = { icon: "jp-user-icon" };

export function accessImpersonationFromData(data: AccessImpersonation): AccessImpersonationWithType {
    return { ...data, $type: "access_impersonation" };
}

export function accessImpersonationListQuery(apiClient: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            const response = getResponseData(await apiClient.GET("/user-resource/impersonation", { signal }));

            return mapFromEntries(response, (o) => [o.uuid, accessImpersonationFromData(o)]);
        },
    });
}

export function startImpersonationMutation(store: Store) {
    const apiClient = store.get(apiClientAtom);

    return mutationOptions({
        mutationKey: ["start_impersonation"],
        async mutationFn({ uuid }: AccessImpersonation) {
            return getResponseData(
                await apiClient.POST("/user-resource/impersonation/{uuid}/start", {
                    params: { path: { uuid } },
                }),
            );
        },
        async onSuccess({ token }, { access_owner }) {
            await store.set(startImpersonationAction, { token, access_owner });
        },

        // Only raise toast on error
        onError(err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Impersonation started",
                error: "Failed to start impersonation",
            });
        },
    });
}

export function useStartImpersonation() {
    const store = useStore();
    return useMutation(startImpersonationMutation(store));
}

export function startAdminImpersonationMutation(store: ReturnType<typeof createStore>) {
    const apiClient = store.get(apiClientAtom);

    return mutationOptions({
        mutationKey: ["start_impersonation"],
        async mutationFn({ body }: { body: AdminImpersonateBody; access_owner: string }) {
            return getResponseData(
                await apiClient.POST("/user-resource/admin/token", {
                    ...jsonEncodedBody,
                    body,
                }),
            );
        },
        async onSuccess({ token }, { access_owner }) {
            // TODO: `access_owner` is not properly returned by the API...
            await store.set(startImpersonationAction, { token, access_owner });
        },

        // Only raise toast on error
        onError(err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Impersonation started",
                error: "Failed to start impersonation",
            });
        },
    });
}

export function useStartAdminImpersonation() {
    const store = useStore();
    return useMutation(startAdminImpersonationMutation(store));
}

//#endregion
