import type { RegisteredRouter } from "@tanstack/react-router";
import { useAtomValue } from "jotai/react";
import { useEffect } from "react";
import { last } from "remeda";
import { analyticsAtom } from "./analytics.ts";

/**
 * "/compute/vm/$location/$vmId/" -> "/compute/vm/__/__/"
 */
function sanitizeRoutePath(path: string) {
    return path.replace(/\$\w+/g, "__");
}

function getSanitizedPath(router: RegisteredRouter) {
    const match = last(router.state.matches)!;
    return sanitizeRoutePath(match.fullPath);
}

/** Send a page view event on each page load of the {@link router} */
export function useRouterAnalytics(router: RegisteredRouter) {
    const analytics = useAtomValue(analyticsAtom);

    // console.debug("initial state: %o", router.state);

    function sendPageViewEvent() {
        analytics.event({ event: "page_view", page_location: getSanitizedPath(router) });
    }

    useEffect(() => {
        // Send initial page view event
        sendPageViewEvent();

        // Send events on each page load (note that the first load does not currently seem to trigger "onLoad")
        return router.subscribe("onLoad", (_location) => {
            sendPageViewEvent();
        });
    }, [analytics, router]);
}
