import type { RouterLinkProps } from "../../components/Action.tsx";
import { routerAtom } from "../../router.store.ts";
import { atomAction } from "../../utils/jotai/atomAction.ts";
import { impersonationAtom, type ImpersonationInfo } from "./impersonation.store.ts";

// NB: Put the action in a separate module to avoid circular dependencies for hot reload (router <-> apikey)
export const stopImpersonatingAction = atomAction(async (get, set) => {
    const info = get(impersonationAtom);
    set(impersonationAtom, undefined);

    const returnPath = info?.returnPath;
    if (returnPath) {
        window.location.replace(returnPath);
    } else {
        await get(routerAtom).navigate({ to: "/" });
    }
});

export const startImpersonationAction = atomAction(
    async (get, set, info: ImpersonationInfo, link: RouterLinkProps = { to: "/" }) => {
        set(impersonationAtom, { returnPath: getFullPath(), ...info });

        await get(routerAtom).navigate(link);
    },
);

function getFullPath() {
    return window.location.pathname + window.location.search + window.location.hash;
}
