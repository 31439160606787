export function downloadBlob(data: Blob | MediaSource, fileName: string) {
    const url = window.URL.createObjectURL(data);
    try {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        document.body.append(a);
        a.click();
        a.remove();
    } finally {
        window.URL.revokeObjectURL(url);
    }
}
