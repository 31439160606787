import { useAtomValue } from "jotai/react";
import React from "react";
import { link } from "../components/Action.tsx";
import { WButton } from "../components/button/WButton.tsx";
import { BaseRouterRoot } from "../modules/main/BaseRouterRoot.tsx";
import { userIsAdminAtom } from "../modules/user/apiOperations.ts";
import { AdminRootLayout } from "./AdminLayout.tsx";

const DefaultApolloProvider = React.lazy(() => import("./graphql/DefaultApolloProvider.tsx"));

/** Root component for /admin routes */
export function AdminRoot() {
    const isAdmin = useAtomValue(userIsAdminAtom);
    if (!isAdmin) {
        return (
            <div className="VStack items-center gap-2">
                <div>Only for administrative users</div>
                <WButton action={link({ to: "/" })}>Back to main app</WButton>
            </div>
        );
    }

    return (
        <DefaultApolloProvider>
            <BaseRouterRoot Layout={AdminRootLayout} />
        </DefaultApolloProvider>
    );
}
