import { Children, Suspense, type ReactNode, type SuspenseProps } from "react";
import { ReportingErrorBoundary } from "../dev/ReportingErrorBoundary.tsx";
import { InfoTooltip } from "./WTooltip.tsx";

interface IsolatedProps {
    fallback?: SuspenseProps["fallback"];
}

const IsolatedError = ({ error }: { error: unknown }) => (
    <InfoTooltip text={String(error)}>
        <span className="text-error">Error</span>
    </InfoTooltip>
);
/** Wrapper component to isolate components from each other - contains an error boundary and suspense */

export function Isolated({ children, fallback }: { children: ReactNode } & IsolatedProps) {
    return (
        <ReportingErrorBoundary ErrorComponent={IsolatedError}>
            <Suspense fallback={fallback}>{children}</Suspense>
        </ReportingErrorBoundary>
    );
}

export function IsolatedChildren({ children, ...props }: { children: ReactNode[] } & IsolatedProps) {
    return Children.map(children, (child) => <Isolated {...props}>{child}</Isolated>);
}
