import { ContentPane } from "../components/ContentPane.tsx";
import { TODO } from "../dev/Todo.tsx";
import { useTitle } from "../utils/react/useTitle.ts";

export function DashboardView() {
    useTitle("Dashboard");

    return (
        <ContentPane>
            <h1 className="font-size-subtitle pb-4">Dashboard</h1>

            <TODO />
        </ContentPane>
    );
}
