import C from "./ErrorFallback.module.css";

import { Link, type NotFoundRouteProps } from "@tanstack/react-router";
import { NotFoundError } from "@warrenio/utils/collections/getAndAssert";
import { useSetAtom } from "jotai/react";
import type { ReactNode } from "react";
import { WButton } from "../../components/button/WButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { resetConfigOverridesAtom, resetConfigOverridesTitle } from "../../config.override.ts";
import { ReportingErrorBoundary } from "../../dev/ReportingErrorBoundary.tsx";
import { cn } from "../../utils/classNames.ts";
import { ApiZodValidationError } from "../api/ApiError.ts";
import { getResourceCreateLink } from "../api/resourceCreateLinks.ts";
import { ResourceNotFoundError } from "../api/resourceTypeException.ts";
import { ErrorBox } from "./ErrorBox.tsx";
import { errorToString } from "./errorToString.tsx";

/** Render an error object into an appropriate, type-specific error message */
export function ErrorMessage({ error }: { error: unknown }) {
    if (error instanceof NotFoundError) {
        return (
            <>
                <h1 className="text-muted font-size-heading">This {error.objectName} is gone</h1>
                <p className="pb-5 text-muted">
                    Time to move on. This item is deleted or you do not have access.
                    {/* TODO: Add some actual text/links here */}
                    {/* <br />
                    Here are some ideas where to go next: */}
                </p>
            </>
        );
    } else if (import.meta.env.DEV && error instanceof ApiZodValidationError) {
        return (
            <>
                <h1 className="font-size-heading">Validation Error</h1>
                <ZodErrorMessage error={error} />
            </>
        );
    }
    return <pre className="text-left whitespace-pre-wrap">{errorToString(error)}</pre>;
}

interface ErrorFallbackProps {
    error: unknown;
}

export function NotFoundPage(_props: NotFoundRouteProps) {
    return (
        <div
            className={cn(
                "size-full text-center flex text-center items-center justify-center bg-layout-2",
                C.ErrorHolder,
            )}
        >
            <div className="p-4">
                <MaskIcon className="jp-cactus-icon size-5rem color-grey-4" />
                <h1 className="text-muted font-size-heading pt-4 justify-center">This page is gone</h1>
                <p className="pb-5 text-muted">Time to move on. Here are some ideas where to go next:</p>
                <div className="flex justify-center gap-4">
                    <Link to="/" className="text-primary">
                        <b>Go to dashboard</b>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export function ErrorFallback({ error }: ErrorFallbackProps) {
    let createLink;
    if (error instanceof ResourceNotFoundError) {
        const { type, objectName } = error;
        const linkProps = getResourceCreateLink(type);
        if (linkProps) {
            createLink = (
                <Link {...linkProps} className="text-primary">
                    <b>Create new {objectName}</b>
                </Link>
            );
        }
    }

    return (
        <ErrorBox>
            <ErrorMessage error={error} />
            <div className="flex flex-wrap flex-col justify-center gap-2">
                {createLink}

                <Link to="/" className="text-primary">
                    <b>Go to dashboard</b>
                </Link>
                {import.meta.env.DEV && <ResetConfigOverridesButton />}
            </div>
        </ErrorBox>
    );
}

export function WErrorBoundary({ children }: { children: ReactNode }) {
    return <ReportingErrorBoundary ErrorComponent={ErrorFallback}>{children}</ReportingErrorBoundary>;
}

/** In case a debug override causes the entire page to error out, offer a backup option */
function ResetConfigOverridesButton() {
    const resetConfig = useSetAtom(resetConfigOverridesAtom);
    return <WButton icon="i-lucide:bug" action={resetConfig} label={resetConfigOverridesTitle} />;
}

export function ZodErrorMessage({ error }: { error: ApiZodValidationError }) {
    const { pathname } = new URL(error.url);
    return (
        <>
            <p>
                The request data did not pass Zod spec validation:
                <br />
                <b>Path: </b>
                <code>{pathname}</code>
                <br />
                <b>Solution: </b>Modify <code>api-spec</code> schema to match the data.
            </p>
            <p className="text-muted font-size-small">(see the console for the full data)</p>
            {error.cause.errors.map(({ code, message, path, ...rest }, i) => (
                <table key={i} className="text-left b b-solid b-grey-4 my-2">
                    <tbody className="border-collapse children:children:px-1">
                        <tr>
                            <th>Message:</th>
                            <td>
                                <code>{message}</code>
                            </td>
                        </tr>
                        <tr>
                            <th>Where:</th>
                            <td>
                                <i>data</i>
                                <code>{path.map((e) => (typeof e === "string" ? e : `[${e}]`)).join(".")}</code>
                            </td>
                        </tr>
                        <tr>
                            <th>Code:</th>
                            <td>
                                <code>{code}</code>
                            </td>
                        </tr>
                        {Object.entries(rest).map(([key, value]) => (
                            <tr key={key}>
                                <th>
                                    <code>{key}:</code>
                                </th>
                                <td>
                                    <pre className="m-0">{JSON.stringify(value, null, 2)}</pre>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ))}
        </>
    );
}
