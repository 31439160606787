import { createLazyFileRoute } from "@tanstack/react-router";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import sleep from "@warrenio/utils/promise/sleep";
import { useEffect } from "react";

export const Route = createLazyFileRoute("/_main/test-error")({
    component: RouteComponent,
});

async function asyncError() {
    await sleep(1000);
    throw new Error("Test async error");
}

function RouteComponent() {
    useEffect(() => {
        discardPromise(asyncError());
        throw new Error("Test error");
    }, []);
}
